<template>
  <div id="team">
    <h1>La TEAM</h1>
    <div id="members-box" class="full">
      <div id="members" ref="members" :style="membersStyle">
        <template v-for="(member, index) of team">
          <div ref="member" :class="index === memberIndex ? 'active' : 'inactive'" class="member"
               @click="setMemberIndex(index)">
            <img :src="getImage(member)" class="member-image"/>
            <div class="member-content" @click.stop>
              <h2 class="member-name">{{ member.fullname }}</h2>
              <div class="member-description" v-html="member.desc"></div>
            </div>
          </div>
        </template>
      </div>
      <div id="member-spacer" :style="`height: ${spacerHeight}px`"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
  metaInfo: {
    title: "Envie de nous connaître ?",
    titleTemplate: '%s - DRiMSCREATiVE ',
    meta: [
      {
        name: "description",
        content: "Agents de Freelances, Agent de Talents"
      }
    ]
  },
  data() {
    return {
      membersStyle: '',
      memberIndex: null,
      spacerHeight: 500,
      team: [
        {
          name: 'steph',
          fullname: 'Stéphanie Naïm',
          desc: `Diplômée de Paris-Dauphine, Stéphanie démarre son parcours chez Sony Music en tant que chef de projet international.<br>
           Après 4 années de travail et de rencontres incroyables, elle souhaite développer son propre projet et elle crée DRiMSCREATiVE  en 2008.
           <br><br>
           Son but : soutenir les freelances dans la recherche de missions et les décharger de la gestion administrative de celles-ci, et côté client, proposer un service réactif, efficace et ultra personnalisé dans la mise en relation avec de nouveaux (supers) talents.
           <br><br>
           <b>Ses passions</b> : la musique, son groupe d’Electropop M/A, et… la musique.`,
        },
        {
          name: 'oph',
          fullname: 'Ophélie Dudemaine',
          desc: `Après des expériences dans différents domaines, Ophélie se découvre une passion pour le graphisme. Elle développe ses compétences en webdesign au sein de l’agence Fullsix pendant plus de 2 ans, puis s’oriente vers le poste de traffic manager, où elle peut exprimer pleinement ses fortes qualités humaines et son sens aigu de l’organisation. En 2011, c’est tout naturellement qu’Ophélie rejoint Stéphanie au sein de DRiMSCREATiVE  en tant qu’agent de freelances… l’équipe de choc se met en place ! <br><br>
           <b>Ses passions</b> : la nature, la récup’ et le karaoké.`,
        },
        {
          name: 'emi',
          fullname: 'Emilie Juntas',
          desc: `Assistante icono, assistante créa print, assistante de production, traffic manager digital, … Emilie a multiplié les casquettes pendant 12 années, au sein de l’agence Textuel La Mine / TBWA. En 2013, Emilie rejoint DRiMSCREATiVE  en tant qu’agent de freelances, et apporte ainsi son dynamisme, son expérience, son professionnalisme et sa fougue : l’équipe de choc se développe ! <br><br>
           <b>Ses passions</b> : sa ville de cœur Lisbonne, la photographie et l’astrologie.`,
        },
        {
          name: 'aziza',
          fullname: 'Aziza Charafi',
          desc: `Après des études de Droit à Birmingham, cette passionnée de sport rejoint une agence de communication spécialisée dans le domaine du sport, où elle travaille pendant près de 4 ans, en tant que traffic manager et responsable de la com°. Avec l’envie d’appréhender de nouveaux horizons, Aziza arrive chez DRiMSCREATiVE  fin 2019, en tant qu’agent de freelances, et complète l’équipe de choc avec beaucoup de fraîcheur, de passion et de fun!! <br><br>
           <b>Ses passions</b> : le football, la musique et le Nutella.`,
        },
        {
          name: 'emilie',
          fullname: 'Emilie Robuchon',
          desc: `Le monde du cinéma n’a aucun secret pour elle!! Après 12 ans en tant qu’assistante décorateur puis régisseur d’extérieur chez EuropaCorp, Emilie souhaite découvrir d’autres univers. Avec une rigueur légendaire et un sens du relationnel affûté, Emilie rejoint DRiMSCREATiVE  courant 2019 en tant qu’assistante administrative et devient ainsi THE contact privilégié côté compta et facturation. <br><br>
           <b>Ses passions</b> : la cuisine, les brocantes et le bricolage`,
        },
      ],
    }
  },
  methods: {
    getImage(member) {
      return `/img/team/${member.name}.jpg`
    },
    setMemberIndex(index) {
      if (index !== this.memberIndex) {
        this.memberIndex = index
      } else {
        this.memberIndex = null
      }
    },
    refreshView(index) {
      let members = this.$refs.members
      let active = members.querySelector('.member.active')
      this.$nextTick(() => {
        if (members.offsetHeight) {
          this.spacerHeight = members.offsetHeight
        }
      })
      if (index === null) {
        this.membersStyle = {
          left: 0,
        }
      } else {
        let wEl = this.$el.offsetWidth
        let elm = this.$refs.member[index]
        let screenWidth = window.innerWidth
        let offset = elm.offsetLeft - (screenWidth - wEl) / 2
        let ctn = active.querySelector('.member-content')
        if (ctn) {
          ctn.style.minWidth = "450px"
          ctn.style.maxWidth = "450px"
        }
        this.membersStyle = {
          left: -offset + 'px',
        }
      }
    },
  },
  computed: {
    spacerStyle() {
      return {
        height: this.spacerHeight,
      }
    },
  },
  watch: {
    memberIndex: {
      immediate: true,
      handler(index) {
        this.$nextTick(() => {
          this.refreshView(index)
        })
      },
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.refreshView(this.memberIndex)
    })
    this.refreshView(this.memberIndex)
  },
}
</script>
<style lang="scss">
#team {

  #members-box {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    margin-bottom: 70px;
  }

  #members {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    transition: 300ms ease-in-out;
  }

  #member-spacer {
    height: 500px;
  }

  .member {
    display: flex;
    align-items: flex-start;

    .member-name {
      font-size: 55px;
      font-weight: 400;
      margin-top: 100px;
    }

    .member-description {
      font-size: 16px;

      b {
        font-weight: 600;
      }
    }

    .member-content {
      display: none;
      padding: 0 60px;
      max-width: 640px;
    }

    .member-image {
      min-width: 20vw;
      max-width: 20vw;
      filter: grayscale(100);
      cursor: pointer;
    }

    &.active {
      max-width: none;

      .member-name {
        margin-top: 3vh;
        line-height: 1.0;
        margin-bottom: 20px;
      }

      .member-content {
        display: block;
      }

    }

  }
}
</style>
