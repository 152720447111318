<template>
  <div id="mentions">
    <h1>Politique de confidentialité</h1>
    <div class="long-text">
      <p>Il est normal que vous accordiez une grande importance à la manière dont vos données sont obtenues et
        utilisées. La confidentialité des données a également une importance particulière pour DRiMSCREATiVE  et nous
        souhaitons communiquer en tout transparence sur l’utilisation de vos données personnelles.</p>
      <p>Par conséquent, nous disposons d’une politique qui décrit comment vos données personnelles seront traitées et
        protégées.</p>
      <h2>Quelles sont les données collectées par DRiMSCREATiVE  ?</h2>
      <p>Selon la nature de votre interaction avec DRiMSCREATiVE , les données personnelles peuvent inclure ce qui suit
        :</p>
      <ul>
        <li>Prénoms et noms</li>
        <li>Fonctions</li>
        <li> Mail</li>
        <li>Numéro de téléphone</li>
        <li>Les données relatives à votre navigation (données relatives à votre comportement sur notre site : l’adresse
          IP, le navigateur et système d’exploitation utilisé, la durée de navigation, la langue et les pages
          visualisées - clics, ouvertures).
        </li>
        <li>Informations relatives aux documents liés au statut professionnelvAdresses</li>
        <li>Informations bancaires</li>
        <li>Portfolio</li>
        <li>Curriculum Vitae</li>
      </ul>
      <h2>Pourquoi vos données sont-elles collectées ?</h2>
      <p>Nous récupérons et utilisons vos données pour :</p>
      <ul>
        <li>Vous présenter nos services et activités</li>
        <li>Vous proposer des missions de freelance</li>
        <li>Nous permettre de vous contacter</li>
        <li>Connaître votre utilisation de notre site afin d’améliorer votre expérience utilisateur</li>
        <li>Le suivi de vos dossiers personnels</li>
      </ul>
      <h2>Qui contrôle vos données personnelles ?</h2>
      <p>DRiMSCREATiVE  contrôle les données personnelles que vous nous communiquez et est responsable de vos données
        personnelles conformément à la loi sur la protection des données en vigueur.</p>
      <p>DRiMSCREATiVE <br>23 rue des Jeûneurs<br>75002 Paris<br>518855481 RCS Paris</p>
      <h2>Qui peut consulter vos données ?</h2>
      <p>Vos données peuvent être transmises au sein de la société DRiMSCREATiVE .<br> Nous ne transférons, ne
        commercialisons et ne troquons en aucun cas vos données à des fins marketing à des parties extérieures à la
        société DRiMSCREATiVE .</p>
      <h2>Quelles sont les conditions légales de traitement des données ?</h2>
      <p>Vos données peuvent être transmises au sein de la société DRiMSCREATiVE . Nous ne transférons, ne
        commercialisons et ne troquons en aucun cas vos données à des fins marketing à des parties extérieures à la
        société DRiMSCREATiVE .</p>
      <h2>Combien de temps collectons-nous vos données ?</h2>
      <p>Nous conserverons vos données personnelles sur la période nécessaire afin d’atteindre les objectifs pour
        lesquels nous les avons collectées.</p>
      <h2>Quels sont vos droits ?</h2>
      <ul>
        <li>
          <h3>Droit d’accès</h3>
          <p>Vous avez le droit de demander des informations sur les données personnelles que nous détenons vous
            concernant. Vous pouvez contacter DRiMSCREATiVE  qui vous transmettra vos données personnelles par e-mail.
          </p>
        </li>
        <li>
          <h3>Droit de portabilité</h3>
          <p>Lorsque DRiMSCREATiVE  traite vos données personnelles par voie automatisée selon votre
            consentement ou un accord, vous avez le droit d’obtenir une copie de vos données transférées à vous ou à une
            autre partie dans un format structuré et courant. Cela ne concerne que les données personnelles que vous
            nous avez transmises.</p>
        </li>
        <li>
          <h3>Droit de rectification</h3>
          <p>Vous avez le droit de demander la rectification de vos données
            personnelles si elles sont inexactes, y compris pour compléter vos données personnelles incomplètes.</p>
        </li>
        <li>
          <h3>Droit de suppression</h3>
          <p>Vous avez le droit de supprimer toute donnée personnelle traitée par DRiMSCREATiVE  à tout
            moment, sauf dans les situations suivantes : </p>
          <ul>
            <li>Vous avez une affaire en cours avec DRiMSCREATiVE </li>
            <li>Vous avez des dettes auprès de DRiMSCREATiVE , quel que soit le mode de paiement</li>
            <li>Vous êtes suspecté(e) d’avoir ou vous avez utilisé nos services de façon frauduleuse</li>
            <li>Vos dettes ont été vendues à un tiers au cours des trois dernières années ou au cours de l’année passée
              pour les clients décédés
            </li>
          </ul>
        </li>
        <li>
          <h3>Votre droit de vous opposer au traitement de vos données sur la base d’un intérêt légitime</h3>
          <p>Vous avez le droit de vous opposer au traitement de vos données personnelles sur la base d’un intérêt
            légitime de DRiMSCREATiVE .<br>
            DRiMSCREATiVE  cessera d’utiliser vos données personnelles à moins que nous prouvions le bien-fondé de
            l’utilisation de vos données
            qui supplante votre intérêt et vos droits ou pour des raisons juridiques.</p>
        </li>
        <li>
          <h3>Votre droit de vous opposer au
            marketing direct (emailing)</h3>
          <p>Vous avez le droit de vous opposer au marketing direct (utilisation dʼemailing commerciaux) notamment à
            l’analyse de profil à des fins marketing Vous pouvez vous désinscrire des supports
            de marketing direct en suivant les instructions figurant en bas de chaque message marketing (se
            désinscrire/unsuscribe)</p>
        </li>
      </ul>
      <h2>Comment exercer vos droits ?</h2>
      <p>Nous prenons la protection des données très au sérieux et sommes disponibles pour répondre à vos questions
        concernant vos droits précités. Vous pouvez nous contacter à l’adresse <a
            href="mailto:contact@drimscreative.com" target="_blank">contact@drimscreative.com</a></p>
      <h2>Droit de réclamation</h2>
      <p>Si vous estimez que DRiMSCREATiVE  utilise vos données personnelles de façon inappropriée, vous pouvez nous
        contacter.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Mentions',
  metaInfo: {
    title: "Confidentialité",
    titleTemplate: '%s - DRiMSCREATiVE ',
    meta: [
      {
        name: "description",
        content: "Agents de Freelances, Agent de Talents"
      }
    ]
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
</style>
