<template>
  <div id="mentions">
    <h1>Mentions Légales</h1>
    <div class="long-text">
      <p>DRiMSCREATiVE est une SARL au capital de 7500€.<br>
        Les freelances, dont les travaux sont présentés sur le site www.drimscreative.com, ont été séléctionnés par
        DRiMSCREATiVE pour leurs qualités professionnelles.<br>
        Tous les travaux qu’ils ont choisi de présenter dans leur portfolio proviennent de leur travail exclusif et
        n’engage aucunement DRiMSCREATiVE .</p>
      <p>En conséquence, DRiMSCREATiVE ne pourra en aucun cas être tenu responsable d’éventuelles erreurs ou omissions
        quant aux différents titulaires des droits d’auteur des références présentées.<br>
        L’ensemble des créations présentées sur ce site relève de la législation sur les droits d’auteur et la propriété
        intellectuelle.</p>
      <p>Toute reproduction ou publication sur un autre support que ceux qui sont gérés par DRiMSCREATiVE sans l’accord
        préalable de leur auteur entraînera des poursuites judiciaires.</p>
      <p>Si vous relevez des erreurs ou omissions dans le contenu de ce site, merci de nous les signaler par mail:
        contact@drimscreative.com</p>
      <p>SIRET : 518 855 481 00033<br>N° TVA Intracommunautaire : FR 83518855481</p>
      <p>Hébergement : IONOS by 1&1</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mentions",
  metaInfo: {
    title: "Mentions légales",
    titleTemplate: '%s - DRiMSCREATiVE ',
    meta: [
      {
        name: "description",
        content: "Agents de Freelances, Agent de Talents"
      }
    ]
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">

</style>
