<template>
  <div id="connexion">
    <h1>Vous connecter</h1>
    <div id="members-box" class="full">
      <connexion-component :next="onConnect"></connexion-component>
    </div>
  </div>
</template>

<script>
import ConnexionComponent from "../components/Connexion";
export default {
  name: "Connexion",
  components: {ConnexionComponent},
  methods: {
    async onConnect () {
      await this.$router.replace(this.$route.query.to).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
